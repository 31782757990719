<template>
	<div class="container">
		<img src="@/assets/imgs/icon/common_icon_machine.png" />
		<p>
			{{ $t('tba.noRobot') }}, <span class="mainColor1" @click="routerTo('/quantification')">{{ $t('tba.startNow') }}</span>
		</p>
	</div>
</template>

<script>
export default {
	name: 'Empty',
	methods: {
		routerTo(linkUrl) {
			this.$router.push(linkUrl)
		}
	}
};
</script>

<style scoped lang="less">
	.container {
		background-color: #FFFFFF!important;
		color: #999999;
		font-size: .24rem;
		height: 3rem;
		// text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	img {
		height: 1.8rem;
		margin-bottom: .2rem;
	}
</style>
